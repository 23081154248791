import { Component, OnInit, ChangeDetectionStrategy, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { IntakeService } from 'apps/intake/src/shared/services/intake.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TitleCasePipe } from '@angular/common';
import * as moment from 'moment';
import { NapPagedSearch } from '@apis/shared/models/nap-paged-search';
import { NapViewModel } from '@apis/shared/models/nap-view.model';
import { Constants } from '@apis/shared/helpers/constants';
import { CancelContraventionModalComponent } from './modals/cancel-contravention-modal/cancel-contravention-modal.component';
import { Contravention } from '@apis/shared/models/contravention.model';
import { IntakeUser } from '../../shared/models/intake-user.model';
import { ContraventionStatusType } from '@apis/shared/models/types/contravention-status-type.model';
import { CancelMultipleContraventionsModalComponent } from './modals/cancel-multiple-contraventions-modal/cancel-multiple-contraventions-modal.component';
import {  } from './modals/cancel-multiple-contraventions-modal/cancel-multiple-contraventions-modal.component';
import { NoticeCancellationRequest } from '@apis/shared/models/notice-cancellation-request.model';
import { IntakeAppProcess, RequestDecisionTypes, RequestStatusTypes, ContraventionTypes, ContraventionStatusTypes, EventTypes, ContraventionDetailTypes, TicketTypes } from '@apis/shared/enums/app.enum';
import { NoticeCancellationRequestMapping } from '@apis/shared/models/notice-cancellation-request-mapping.model';
import { ConnectivityService } from '../../shared/services/connectivity.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Document } from '@apis/shared/models/document.model';
import { DocumentService } from 'apps/intake/src/shared/services/document.service';
import { RoadsideAppeal } from '@apis/shared/models/roadside-appeal.model';
import { RoadsideAppealOutcomeType } from '@apis/shared/models/types/roadside-appeal-outcome-type.model';
import * as fileSaver from 'file-saver';
import { Review } from '@apis/shared/models/review.model';
import { CancelIrsSeizureConfirmationModalComponent } from './modals/cancel-irs-seizure-confirmation-modal/cancel-irs-seizure-confirmation-modal.component';
import { StopInformation } from '@apis/shared/models/stop-information.model';
import { environment } from '../../environments/environment';
import { TicketPagedSearch } from '@apis/shared/models/ticket-paged-search';
import { TicketViewModel } from '@apis/shared/models/ticket-view.model';
import { TicketService } from '../../shared/services/ticket.service';
import { Ticket } from '@apis/shared/models/ticket.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { LocationType } from '@apis/shared/models/types/location-type.model';
import { DisclosureDocument } from '@apis/shared/models/disclosure-document.model';
import { WithdrawTrafficTicketsModalComponent } from './modals/withdraw-traffic-tickets-modal/withdraw-traffic-tickets-modal.component';
import { TicketWithdrawalRequest } from '@apis/shared/models/ticket-withdrawal-request.model';

export enum ContraventionSeizureStatus {
  DetailsRequired = 'Details required',
  DocumentsRequired = 'Documents required',
  Submitted = 'Submitted',
  Cancelled = 'Cancelled'
}

export enum SafeRoadsStatus {
  InReview = 'In Review',
  Cancelled = 'Cancelled',
  Unpaid = 'Unpaid',
  Overdue = 'Overdue',
  Paid = 'Paid',
  Issued = 'Issued'
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  private availableOffline: boolean = environment.availableOffline;
  availableTSA: boolean = environment.availableTSA;
  contravention: Contravention;

  // printing related variables
  //
  stopInformation: StopInformation;
  irsContravention: Contravention;
  isRefusal: boolean = false;
  ContraventionTypes = ContraventionTypes;
  roadsideAppeal: RoadsideAppeal;
  roadsideAppealOutcomeTypes: RoadsideAppealOutcomeType[];

  statusElement: HTMLElement;
  noticeNumberElement: JQuery<HTMLElement>;
  filterStartDateElement: JQuery<HTMLElement>;
  filterEndDateElement: JQuery<HTMLElement>;
  policeFileNumberElement: JQuery<HTMLElement>;
  recipientNameElement: JQuery<HTMLElement>;
  issuingOfficerRegimentalNumberElement: JQuery<HTMLElement>;
  safeRoadsStatusElement: HTMLElement;
  napTableElement: HTMLElement;
  previousLink: JQuery<HTMLElement>;
  nextLink: JQuery<HTMLElement>;
  clearFiltersLink: JQuery<HTMLElement>;

  contraventionStatusTypes: ContraventionStatusType[] = []; 
  contraventionSeizureStatuses: string[] = [];
  selectedContraventionSeizureStatus: string[] = [];
  safeRoadsStatuses: string[] = [];
  selectedSafeRoadsStatuses: string[] = [];
  noticeNumber: string = '';
  recipientName: string = '';
  issuingOfficerRegimentalNumber: string = '';
  policeFileNumber: string = '';
  filterStartDate: Date;
  filterEndDate: Date;

  // support for the varying views on the dashboard
  //
  Resource:any = Constants.Resource;
  Permission:any = Constants.Permission;

  isIssuingOfficer: boolean = false;
  isDataEntryOrSupervisor: boolean = false;
  isSuperUser: boolean = false;
  isAllowedAccess: boolean = true;
  isResetEnabled: boolean = false;
  isDataLoaded: boolean = false;
  isAllSelected: boolean = false;
  curStopId: number = -1;
  curRowStyle: string = 'even-row';
  spinnerMessage: string;

  dashboardSearch: NapPagedSearch = this.getDefaultFilterSettings();

  naps: NapViewModel[] = [];
  filteredNaps: NapViewModel[] = [];   
  selectedNaps: NapViewModel[] = [];
  filteredTickets: TicketViewModel[] = [];   
  selectedTickets: TicketViewModel[] = [];

  pageNumber: number = 1;
  totalPages: number;
  pageNumbers: number[] = [];
  pageRange: number = 5;
  pageSize: number = 25;
  totalCount: number;
  sortDirection = "desc";
  orderBy = "ContraventionNumber";
  isLastRange: boolean = false;  
  isOnline: boolean;
  user: IntakeUser;
  RequestDecisionTypes = RequestDecisionTypes;
  TicketTypes = TicketTypes;
  ticketTypeId: number = TicketTypes.ContraventionsAndSeizures; //By Default contraventions dashboard

  violationTicketNumber: string = "";
  accusedFirstName: string = "";
  accusedLastName: string = "";
  licencePlate: string = "";
  location: LocationType;
  filterOffenceDateFrom: string = "";
  filterOffenceDateTo: string = "";
  officerBadgeNumber: string = "";
  trialSetIndicator: string = "";
  filterTrialDateFrom: string = "";
  filterTrialDateTo: string = "";

  ticketDashboardSearch: TicketPagedSearch = this.getDefaultTicketFilterSettings();

  locationTypes: any;
  filteredLocations: Observable<string[]>;
  locationControl = new UntypedFormControl();
  
  private columns = [{
      propertyName: "ContraventionNumber",
      ascending: false    
    }, {
      propertyName: "Description",
      ascending: false    
    }, {
      propertyName: "IssuingOfficer",
      ascending: false    
    },{
      propertyName: "RecipientName",
      ascending: false    
    }, {
      propertyName: "PoliceFileNumber",
      ascending: false  
    }, {
      propertyName: "IssueDate",
      ascending: false    
    }, {
      propertyName: "ApisStatus",
      ascending: false
    }
  ];
         
  datePickerConfig: Partial<BsDatepickerConfig>;
  isSubmitClicked: boolean = false;
  isSearchClicked: boolean = false;

  bodyElement: JQuery<HTMLElement>;
  
  // operatorSearch: OperatorSearch = new OperatorSearch();;
  // operatorInformation: OperatorInformation[];
  errorMessage: string;
  searchValidationError: string;
  newContraventionError: string;

  constructor(private intakeService: IntakeService,
              private readonly ticketService: TicketService, 
              private readonly viewContainerRef: ViewContainerRef,
              private readonly connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService,
              private documentService: DocumentService,
              private readonly spinner: NgxSpinnerService,
              private router: Router,
              public titleCasePipe: TitleCasePipe, 
              private changeDetectorRef: ChangeDetectorRef) 
  { 
    this.datePickerConfig = Object.assign({}, 
    {
      containerClass: 'theme-dark-blue', 
      showWeekNumbers: false,
      dateInputFormat: 'YYYY/MM/DD',
      minDate: new Date(1900,0,1), // 1900/01/01 - Month is 0 based index
      maxDate: new Date(9999,11,31), // 2099/12/31 - Month is 0 based index
      isAnimated: true,
      customTodayClass: 'custom-today-class'
    });
    
    //Get Location Types
    this.locationTypes = this.localStorageService.getLocationTypes();
    this.locationControl.setValidators(this.validateLocations(this.locationTypes));
  }

  ngAfterViewInit(): void {
    // If Traffic is the default dashboard then highlight the Traffic Ticket button
    if (this.ticketTypeId == TicketTypes.Violations && !$('#tabHeader_Violations').hasClass("selected")) {
      $(`.tab-header-item`).removeClass("selected");
      $(`#tabHeader_Violations`).addClass("selected");  
    }
  }

  ngOnInit(): void {

    this.spinnerMessage = "Loading data. Please wait...";

    // for offline, set the current Intake App status
    this.localStorageService.setIntakeAppProcess(IntakeAppProcess.ViewingDashboard);

    // variable to maintain the current offline indexDb key
    this.localStorageService.clearCurrentOfflineId();

    // Determine if the current user is superUser, Data/Supervisor, or issuingOfficer
    // Start with superUser, then supervisor, the issuing officer so that we get the most significant
    // access if users can have multiple roles
    //
    this.determineMostSignigicantRole();

    this.bodyElement = $(document.body);
    this.napTableElement = document.getElementById("napTable");
    this.statusElement = document.getElementById("contraventionDetailsMultiselect");
    this.filterStartDateElement = $("#filterStartDate");
    this.filterEndDateElement = $("#filterEndDate");
    this.policeFileNumberElement = $("#policeFileNumber");
    this.noticeNumberElement = $("#noticeNumber");
    this.issuingOfficerRegimentalNumberElement = $("#issuingOfficerRegimentalNumber");
    this.recipientNameElement = $("#recipientNameElement");
    this.safeRoadsStatusElement = document.getElementById("safeRoadsStatusMultiselect");
    this.user = this.localStorageService.getUser();

    this.previousLink = $(".previous-link");
    this.nextLink = $(".next-link");
    this.clearFiltersLink = $(".clear-filters");

    if (window.location.search.includes("page")) {
      let search = window.location.search;        
      this.pageNumber = parseInt(search.split("=")[1]);
    }

    this.setWindowHistory();
    
    window.onpopstate = (ev: any) => {          
      if (window.location.search.includes("page")) {
        let search = window.location.search;        
        this.pageNumber = parseInt(search.split("=")[1]);
        this.setFilteredNaps();   
      }           
    };

    this.selectedContraventionSeizureStatus = [];
    this.contraventionSeizureStatuses = Object.values(ContraventionSeizureStatus)
                                              .filter(value => typeof value === 'string');

    this.selectedSafeRoadsStatuses = [];
    this.safeRoadsStatuses = Object.values(SafeRoadsStatus)
                                  .filter(value => typeof value === 'string');
       
    var searchFilters = this.intakeService.getSearchFilters();

    if (searchFilters != null) {
      searchFilters.apisStatuses.length > 0 ? this.selectedContraventionSeizureStatus = JSON.parse(searchFilters.apisStatuses)
        : null;
      searchFilters.safeRoadsStatuses.length > 0 ? this.selectedSafeRoadsStatuses = JSON.parse(searchFilters.safeRoadsStatuses)
        : null;

      this.isResetEnabled = true;
    }

    this.showOrHideSearchBlock();
    this.setFilteredNaps(); 

    //Get Types
    this.locationTypes = this.localStorageService.getLocationTypes();
    this.filteredLocations = this.locationControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterLocation(value))
    );
  }

  async checkAndPostOfflineNaps(loadNaps: boolean = false) {

    if (!this.availableOffline) {
      return;
    }

    let isOfflineStops = false;
    // check if we have any IndexDb saved stops... if so, post them to the API
    if (this.isOnline) {
      await this.intakeService.isOfflineStops().then( result => {
        isOfflineStops = result;
      });
      if (isOfflineStops) {
          this.isDataLoaded = false;
          this.spinnerMessage = "Posting offline stop information. Please wait...";
          this.spinner.show();

          await this.intakeService.postOfflineStops().then( stopsUpdated => {

            if (stopsUpdated>0 && loadNaps) {
              this.setFilteredNaps();  
            }
            this.spinner.hide();
            this.spinnerMessage = "Loading data. Please wait...";
            this.isDataLoaded = true;

          },
          (error) => {
            this.spinner.hide();
            this.spinnerMessage = "Loading data. Please wait...";
            this.isDataLoaded = true;
            this.errorMessage = "Problem posting offline contraventions/seizures."
          });
    
      }
    }
  }

  determineMostSignigicantRole() {
    this.isAllowedAccess = false;
    let rolePermissionKey = this.localStorageService.getRolePermissions();
    if (rolePermissionKey!==null && rolePermissionKey!==undefined) {
      rolePermissionKey.forEach( p => {
          if (p.roleName==='PoliceSuperUser' || p.roleName==='MPEISDSuperUser') {
            this.isSuperUser = true;
          } else {
            if (p.roleName==='DataEntryClerk') {
              this.isDataEntryOrSupervisor = true;
            } else {
              if (p.roleName==='PoliceOfficer') {
                this.isIssuingOfficer = true;
              }
            }
          }
      });

      if (this.isSuperUser) {
        this.isDataEntryOrSupervisor = false;
        this.isIssuingOfficer = false;
      } else {
        if (this.isDataEntryOrSupervisor) {
          this.isIssuingOfficer = false;
        }
      }

      if (this.isSuperUser || this.isDataEntryOrSupervisor || this.isIssuingOfficer) {
        this.isAllowedAccess = true;
      }

      // Check if logged in user has TrafficTicket or DataEntryClerk permissions then set the Ticket Dashboard as default
      if (rolePermissionKey.some(x => x.roleName==='TrafficTicket' || x.roleName==='DataEntryClerk'))
      {
        this.ticketTypeId = TicketTypes.Violations;
      }
    }
  }

  handleKeyboardEvent(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.onSearchButtonClick();
    }
  }

  isMobile(): boolean {
    return false;
  }

  showOrHideSearchBlock() {
    var divElement = document.getElementById("searchElement");
    if (divElement!==null && divElement!==undefined) {
      if (this.isOnline) {
        divElement.style.display = "block";
      } else {
        divElement.style.display = "none";
      }   
    }
  }

  onSubmitContraventionClick() {
    this.user = this.localStorageService.getUser(); // Refresh the user, in case he/she recently updated his/her profile
    if (!this.user.badgeNumber) {
      this.newContraventionError = "Invalid Regimental Number. Please update your profile and try again.";
      return;
    }
    
    this.connectivityService.getOnlineStatusAsync().then( response => {
      this.isOnline = response;
      this.intakeService.resetStopInformationModel();

      // variable to maintain the current offline indexDb key
      this.localStorageService.clearCurrentOfflineId();
      
      this.router.navigateByUrl('/contravention/submission/recipient-information');
    });
  }

  // Printing Related functions

  printNotices(stopInformationId, isOffline)
  {
    this.spinner.show();
    this.isDataLoaded = false;

    this.intakeService.getStopInformationByIdAsync(stopInformationId)
      .subscribe((stopInformation: StopInformation) => {
        this.stopInformation = stopInformation; 

        // gather Roadside Appeal details
        if (this.stopInformation.isIRSSelected)
        {
          var irsContraventionTypeIds = this.localStorageService.getContraventionTypes().filter(x => x.isIRS).map(s => s.id);
          this.irsContravention = this.stopInformation.contraventions.find(x => irsContraventionTypeIds.includes(+x.contraventionTypeId));

          // JTI-3170 - isRefusal is true ONLY is refusal is the ONLY detail selected
          //
          var detailsSelected = this.irsContravention?.contraventionDetails?.split(",");
          var totalDetailsSelected = detailsSelected?.length;
          
          this.isRefusal = (
              (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail1st && 
                (detailsSelected.includes(ContraventionDetailTypes.IRSFail1stRefusal.toString()) && totalDetailsSelected===1)) ||
              (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail2nd && 
                (detailsSelected.includes(ContraventionDetailTypes.IRSFail2ndRefusal.toString()) && totalDetailsSelected===1)) ||
              (this.irsContravention.contraventionTypeId == ContraventionTypes.IRSFail3rd && 
                (detailsSelected.includes(ContraventionDetailTypes.IRSFail3rdRefusal.toString()) && totalDetailsSelected===1))
          )

          if (this.irsContravention.contraventionTypeId != ContraventionTypes.IRS24 
            && !this.isRefusal
            && this.irsContravention.roadsideAppeal == null) {
            this.irsContravention.roadsideAppeal = new RoadsideAppeal();  
          }
    
          this.roadsideAppeal = this.irsContravention.roadsideAppeal;
        }
    
        if (this.stopInformation.documents.length > 0)
        {
          this.stopInformation.documents.filter(x=>!x.isDeleted).forEach(document => {
            this.DownloadDocument(document);  
          });

          irsContravention: Contravention;

          //Provide Roadside Appeal Notice if IRS is selected
          if (this.stopInformation.isIRSSelected 
            && this.irsContravention?.contraventionTypeId != ContraventionTypes.IRS24 
            && !this.isRefusal
            && !this.isReadOnly())
          {
            let link = document.createElement("a");
            link.setAttribute('type', 'hidden');
            link.download = "IRS-Roadside-Appeal";
            link.href = this.stopInformation.isMobilePrint? "assets/IRS-Roadside-Appeal-Mobile.pdf" : "assets/IRS-Roadside-Appeal.pdf";
            link.click();
            link.remove();
          }

          this.spinner.hide();
          this.isDataLoaded = true;
        }
    },
    (error) => {
      this.spinner.hide();
      this.isDataLoaded = true;
      this.errorMessage = "Problem loading documents for printing."
    });
  }

  printTSANotices(ticketNumber: string)
  {
    this.spinner.show();    

    this.ticketService.getTicket(ticketNumber)
    .subscribe((result: Ticket) => {
      //Get the violation ticket notice
      if (result?.disclosureDocuments)
      {
        var ticketNotice = result.disclosureDocuments.find(x => x.type == "Violation Ticket");
        if (ticketNotice)
        {
          //Create a link to download disclosure and then remove it
          let link = document.createElement("a");
          link.setAttribute('type', 'hidden');
          link.download = ticketNotice.name;
          link.href = ticketNotice.url;
          link.target = "_blank";
          link.click();
          link.remove();
        }
      }
      this.spinner.hide();
    });
  }

  isCancelledStatus(contraventionStatus: string): boolean {
    switch (contraventionStatus) {
      case 'Cancelled': return true;
    }

    return false; 
  }

  isCancelledOrPendingStatus(nap: NapViewModel): boolean {
    let isCancelledOrPending = 
      nap.isNoticeCancellationRequestSubmitted || 
      nap.noticeCancellationRequestDecisionTypeId == RequestDecisionTypes.Approved ||
      nap.contraventionStatusTypeName == SafeRoadsStatus.Cancelled;

    return isCancelledOrPending;
  }

  downloadDecisionDocument(stopInformationId, reviewNumber) {
    this.spinner.show();
    this.isDataLoaded = false;

    // 29 - Review Decision Letter [Cancelled with Review]
    // 30 - Request Decision Letter 
    // 40 - ???? [Cancelled without Review]
    // 74 - Court Decision
    let decisionDocumentIds: number[] = [29, 40];

    this.intakeService.getStopInformationByIdAsync(stopInformationId).subscribe((stopInformation: StopInformation) => {
      
      this.stopInformation = stopInformation; 

      this.intakeService.getReviewByNumber(reviewNumber).subscribe((review: Review) => {
          if (review.documents.length > 0)
          {
            review.documents.filter(x=>!x.isDeleted).forEach(document => {
              let dId = +document.documentTypeId;
              if (decisionDocumentIds.includes(dId)) {
                this.DownloadReviewDocument(review, document); 
              }
            });

            this.spinner.hide();
            this.isDataLoaded = true;
            this.changeDetectorRef.detectChanges();
          } else {
            this.spinner.hide();
            this.isDataLoaded = true;
            this.errorMessage = "No decision documents found."
            this.changeDetectorRef.detectChanges();
          }

      },
      (error) => {
        this.spinner.hide();
        this.isDataLoaded = true;
        this.errorMessage = "Problem loading decision document.";
        this.changeDetectorRef.detectChanges();
      }); 
    },
    (error) => {
      this.spinner.hide();
      this.isDataLoaded = true;
      this.errorMessage = "Problem loading decision document."
      this.changeDetectorRef.detectChanges();
    });
  }

  isReadOnly()
  {
    return this.isIRSCancelled() || this.isIRSReIssued();
  }

  isIRSCancelled()
  {
    return this.irsContravention?.contraventionStatusTypeId == ContraventionStatusTypes.Cancelled;
  }

  isIRSReIssued()
  {
    return this.irsContravention?.events?.filter(x=> x.eventTypeId == EventTypes.ContraventionReIssued).length > 0;
  }

  checkIfPrintable(nap: NapViewModel): boolean {
    if (nap.isEmergencySubmission || nap.isPaperSubmission)
      return false;

    return true;
  }

  DownloadReviewDocument(review: Review, document: Document)
  {
    if (document)
    {
      var documentRefTypeName = "Reviews";
      var documentRefTypeNumber =  review.reviewNumber;
      var storageFileName = `${document.contentGuid}.${document.documentExtension}`;

      this.documentService.downloadDocument("", documentRefTypeName, documentRefTypeNumber, storageFileName , document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      },
      (error) => {
        this.errorMessage = "Problem loading decision document."
      });
    }
  }

  DownloadDocument(document: Document)
  {
    if (document && document.uploadedDate) // If document is uploaded, and not a placeholder
    {
      var documentRefTypeName = this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1? this.getDocumentRefName() : "Stop";
      var documentRefTypeNumber = this.stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1? this.getDocumentRefNumber() : this.stopInformation.stopInformationId.toString();
      var storageFileName = `${document.contentGuid}.${document.documentExtension}`;

      if (document.version == Constants.DocumentVersion.VERSION3)
      {
       this.documentService.getDocumentSasUri("", documentRefTypeName, documentRefTypeNumber, storageFileName , document.documentName)
       .subscribe((result: any) => {
          window.open(result.documentSasUri, "_blank");
       });

        return;
      }

      this.documentService.downloadDocument("", documentRefTypeName, documentRefTypeNumber, storageFileName , document.documentName)
      .subscribe((result: any) => {
        if (result)
        { 
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      },
      (error) => {
        this.errorMessage = "Problem loading document."
      });
    }
  }

  getDocumentRefNumber(): string {
    if (this.stopInformation.contraventions.length > 0)
      return this.stopInformation.contraventions[0].contraventionNumber;

    if (this.stopInformation.vehicleSeizures.length > 0)
      return this.stopInformation.vehicleSeizures[0].seizureNumber;
      
    return "";  
  }

  getDocumentRefName(): string {
    if (this.stopInformation.contraventions.length > 0)
      return "Contraventions";

    if (this.stopInformation.vehicleSeizures.length > 0)
      return "VehicleSeizures";
      
    return "";  
  }

  getRowStyle(currentStopId): string {

    let stopId = parseInt(currentStopId, 10)
    if (stopId!==this.curStopId) {
      this.curStopId = stopId;
      if (this.curRowStyle==='even-row') {
        this.curRowStyle='odd-row';
      } else {
        this.curRowStyle='even-row';
      }
    }
    return this.curRowStyle;
  }

  getOfficerUserId() : string {
    if (this.isIssuingOfficer) {
      return this.user.userName;
    }
    return '';
  }

  private setWindowHistory(): void {    
    window.history.pushState(null, "", `/user-dashboard?page=${this.pageNumber}`);
  }  

  isIrsSeizure(nap: NapViewModel): boolean {
    if(nap.description=='IRS Seizure')
      return true;
    return false;
  }

  showCancelContraventionModal(nap: NapViewModel) {

    // show warning/confirmation modal
    // if model returns true then continue - otherwise, do nothing and return to dashboard
    let modalResult = true;

    // if (nap.isSeizure)
    // {
    //   let continueToCancel: boolean = true;

    if (this.isIrsSeizure(nap)) {
      // show the confirmation modal and set the modalResult the return value
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(CancelIrsSeizureConfirmationModalComponent);

      //Create notice cancellation request 
      var noticeCancellationRequest = new NoticeCancellationRequest ({
        requestStatusTypeId: RequestStatusTypes.New,
        requestingOfficerId: this.user.userId
      });
      
      var noticeCancellationRequestMapping = new NoticeCancellationRequestMapping();
      noticeCancellationRequestMapping.vehicleSeizureId = nap.vehicleSeizureId;
      noticeCancellationRequestMapping.vehicleSeizure = nap; 
      noticeCancellationRequestMapping.recordNumber = nap.contraventionNumber;
      noticeCancellationRequest.noticeCancellationRequestMappings.push(noticeCancellationRequestMapping);
      
      componentRef.instance.noticeCancellationRequest = Object.assign({}, noticeCancellationRequest);
      componentRef.instance.close.subscribe((result: boolean) => {
        modalResult = result;  


        if (modalResult) {
          this.viewContainerRef.clear();
          const componentRef = this.viewContainerRef.createComponent(CancelMultipleContraventionsModalComponent);
    
          //Create notice cancellation request 
          var noticeCancellationRequest = new NoticeCancellationRequest ({
            requestStatusTypeId: RequestStatusTypes.New,
            requestingOfficerId: this.user.userId
          });
          
          var noticeCancellationRequestMapping = new NoticeCancellationRequestMapping();
          if (nap.isSeizure)
          {
            noticeCancellationRequestMapping.vehicleSeizureId = nap.vehicleSeizureId;
            noticeCancellationRequestMapping.vehicleSeizure = nap;
          }
          else
          {
            noticeCancellationRequestMapping.contraventionId = nap.contraventionId;
            noticeCancellationRequestMapping.contravention = nap;
          }
    
          noticeCancellationRequestMapping.recordNumber = nap.contraventionNumber;
          noticeCancellationRequest.noticeCancellationRequestMappings.push(noticeCancellationRequestMapping);
          
          componentRef.instance.noticeCancellationRequest = Object.assign({}, noticeCancellationRequest);
          componentRef.instance.close.subscribe((result: boolean) => {
            if (result) {
              // refresh the list
              this.setFilteredNaps();
            }        
            componentRef.destroy();
          });  
    
        }
    

        componentRef.destroy();
      });  
    } else {
      // modal dialog        
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(CancelMultipleContraventionsModalComponent);

      //Create notice cancellation request 
      var noticeCancellationRequest = new NoticeCancellationRequest ({
        requestStatusTypeId: RequestStatusTypes.New,
        requestingOfficerId: this.user.userId
      });
      
      var noticeCancellationRequestMapping = new NoticeCancellationRequestMapping();
      if (nap.isSeizure)
      {
        noticeCancellationRequestMapping.vehicleSeizureId = nap.vehicleSeizureId;
        noticeCancellationRequestMapping.vehicleSeizure = nap;
      }
      else
      {
        noticeCancellationRequestMapping.contraventionId = nap.contraventionId;
        noticeCancellationRequestMapping.contravention = nap;
      }

      noticeCancellationRequestMapping.recordNumber = nap.contraventionNumber;
      noticeCancellationRequest.noticeCancellationRequestMappings.push(noticeCancellationRequestMapping);
      
      componentRef.instance.noticeCancellationRequest = Object.assign({}, noticeCancellationRequest);
      componentRef.instance.close.subscribe((result: boolean) => {
        if (result) {
          // refresh the list
          this.setFilteredNaps();
        }        
        componentRef.destroy();
      });       
    }
  }

  private clearFilters(): void {      
    if (this.ticketTypeId == TicketTypes.ContraventionsAndSeizures) {
      this.selectedContraventionSeizureStatus = [];
      this.selectedSafeRoadsStatuses = [];
      this.noticeNumber = '';
      this.policeFileNumber = '';
      this.recipientName = '';
      this.issuingOfficerRegimentalNumber = '';
      this.filterStartDate = null;
      this.filterEndDate = null;

      this.intakeService.clearSearchFilters();

      this.dashboardSearch = this.getDefaultFilterSettings();  
    }
    else if (this.ticketTypeId == TicketTypes.Violations) {
      this.violationTicketNumber = '';
      this.accusedFirstName = '';
      this.accusedLastName = '';
      this.filterOffenceDateFrom = '';
      this.filterOffenceDateTo = '';
      this.licencePlate = '';
      this.location = null;
      this.officerBadgeNumber = '';
      this.trialSetIndicator = '';
      this.filterTrialDateFrom = '';
      this.filterTrialDateTo = '';
      this.ticketDashboardSearch = this.getDefaultTicketFilterSettings();
    }
  }

  getDefaultFilterSettings(): NapPagedSearch {
    this.filterEndDate = new Date();
    this.filterStartDate = new Date();
    this.filterStartDate.setDate(this.filterEndDate.getDate()-7); 

    this.user = this.localStorageService.getUser();
    this.issuingOfficerRegimentalNumber = this.user.badgeNumber;

    var searchFilters = this.intakeService.getSearchFilters();

    if (searchFilters != null) {
      this.noticeNumber = searchFilters.noticeNumber;
      this.policeFileNumber = searchFilters.policeFileNumber;
      this.recipientName = searchFilters.recipientName;
      this.issuingOfficerRegimentalNumber = searchFilters.issuingOfficerRegimentalNumber;
      this.filterStartDate = searchFilters.fromDate != null ? searchFilters.fromDate : "";
      this.filterEndDate = searchFilters.toDate != null ? searchFilters.toDate : "";
    }

    return {
      noticeNumber: this.noticeNumber,
      policeFileNumber: this.policeFileNumber,
      recipientName: this.recipientName,
      issuingOfficerRegimentalNumber: this.issuingOfficerRegimentalNumber,
      safeRoadsStatuses: JSON.stringify(this.selectedContraventionSeizureStatus),    
      apisStatuses: JSON.stringify(this.selectedSafeRoadsStatuses),
      fromDate: (moment(this.filterStartDate)).format('YYYY-MM-DD'),
      toDate: (moment(this.filterEndDate)).format('YYYY-MM-DD'),
      contraventionStatusTypeIds: '',
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'noticeNumber',
      sortDirection: 'asc',
      officerUserId: this.getOfficerUserId()
    } as NapPagedSearch;
  }

  getDefaultTicketFilterSettings(): TicketPagedSearch {
    this.user = this.localStorageService.getUser();
    this.officerBadgeNumber = this.user.badgeNumber;
    this.searchValidationError = '';

    return {
      accusedFirstName: '',
      accusedLastName: '',
      ticketNumber: '',
      licencePlateNumber: '',
      locationCode: '',
      dateIssued: '',
      pageNumber: 1,
      pageSize: 25,
      orderBy: '',
      sortDirection: '',
      issuingOfficer : '',
      officerBadgeNumber: this.user.badgeNumber,
      offset: 0,
      offenceDateFrom: '',
      offenceDateTo: '',
      trialSetIndicator: '',
      trialDateFrom: '',
      trialDateTo: ''
    } as TicketPagedSearch;
  }

  setFilteredNaps(loadMore: boolean = false): void {
    if (this.isAllowedAccess) {

      this.connectivityService.getOnlineStatusAsync().then( response => {
        this.isOnline = response;

        this.checkAndPostOfflineNaps(false);
        this.spinnerMessage = "Loading data. Please wait...";

        if (this.ticketTypeId == TicketTypes.ContraventionsAndSeizures)
        {
          this.dashboardSearch = {
            noticeNumber: this.noticeNumber.toString().trim().toLowerCase(),
            policeFileNumber: this.policeFileNumber.toString().trim().toLowerCase(),
            issuingOfficerRegimentalNumber: this.issuingOfficerRegimentalNumber.toString().trim(),
            recipientName: this.recipientName.toString().trim().toLowerCase(),
            apisStatuses: this.selectedContraventionSeizureStatus.length > 0 ? JSON.stringify(this.selectedContraventionSeizureStatus) : "",
            safeRoadsStatuses: this.selectedSafeRoadsStatuses.length > 0 ? JSON.stringify(this.selectedSafeRoadsStatuses) : "",

            fromDate: this.filterStartDate!==null && this.filterStartDate?.toString().length > 0 ? (moment(this.filterStartDate)).format('YYYY-MM-DD') : "",
            toDate: this.filterEndDate!==null && this.filterEndDate?.toString().length > 0 ? (moment(this.filterEndDate)).format('YYYY-MM-DD') : "",
    
            contraventionStatusTypeIds: '',
    
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortDirection: this.sortDirection,
            orderBy: this.orderBy,
    
            officerUserId: '' 
            // JTI-2681 change; show all officers ... 
            //officerUserId: this.getOfficerUserId()
          }
          
          this.isDataLoaded = false;
    
          if (this.selectedContraventionSeizureStatus.length > 0) {
            this.dashboardSearch.apisStatuses = this.selectedContraventionSeizureStatus.join(",");
          }
          if (this.selectedSafeRoadsStatuses.length > 0) {
            this.dashboardSearch.safeRoadsStatuses = this.selectedSafeRoadsStatuses.join(",");
          }

          this.spinner.show();
          if (this.isOnline) {

            // for offline, set the current Intake App status
            this.localStorageService.setIntakeAppProcess(IntakeAppProcess.ViewingDashboard);
    
            this.intakeService.findContraventionsAndSeizuresAsync(this.dashboardSearch).subscribe(
              (result: any) => {
              
                this.totalCount = result.totalCount
                this.filteredNaps = Array.from(result.items);

                // setup cbId's
                this.filteredNaps.forEach( nap => {
                  nap.cbId = 'cb'+nap.contraventionNumber;
                });

                this.setPageNumbers();
                this.isAllSelected = false;
    
                if (this.availableOffline) {
                  this.intakeService.checkCacheIntegrity(this.filteredNaps, false);
                }

                this.spinner.hide();
                this.isDataLoaded = true;

                this.changeDetectorRef.detectChanges();

              },
              (error) => {
                this.spinner.hide();
                this.errorMessage = "Problem loading the contraventions/seizures."
                this.isDataLoaded = true;
              }
            );
          } else {
    
            // for offline, set the current Intake App status
            this.localStorageService.setIntakeAppProcess(IntakeAppProcess.ViewingOfflineDashboard);
    
            // Need to read the IndexDB 'stop' list and display...
            this.totalCount = 0
            //this.filteredNaps = [];
            this.intakeService.getOfflineStops().then( naps=> {
              if (naps!==null && naps.length>0) {
                this.filteredNaps = naps;
              } else {
                this.filteredNaps = [];
              }
              this.setPageNumbers();
              this.isAllSelected = false;

              if (this.availableOffline) {
                this.intakeService.checkCacheIntegrity(this.filteredNaps, false);
              }

              this.spinner.hide();
              this.isDataLoaded = true;

              this.changeDetectorRef.detectChanges();
            },
              (error) => {
                this.spinner.hide();
                this.errorMessage = "Problem loading the offline contraventions/seizures."
                this.isDataLoaded = true;
              }
            );
    
          }
        } 
        else if (this.ticketTypeId == TicketTypes.Violations)
        {
          if (!loadMore) {
            this.ticketDashboardSearch = {
              accusedFirstName: this.accusedFirstName.toString().trim().toLowerCase(),
              accusedLastName: this.accusedLastName.toString().trim().toLowerCase(),
              ticketNumber: this.violationTicketNumber.toString().trim().toLowerCase(),
              officerBadgeNumber: this.officerBadgeNumber.trim().toLowerCase(),
              locationCode: this.location?.code != undefined ? this.location?.code?.toLowerCase() : '',
              licencePlateNumber: this.licencePlate.trim().toLowerCase(),
              dateIssued: '',
              pageNumber: this.pageNumber,
              pageSize: 25,
              sortDirection: '',
              orderBy: '',
              offset: 0,
              offenceDateFrom: this.filterOffenceDateFrom ? (moment(this.filterOffenceDateFrom)).format('YYYYMMDD') : '',
              offenceDateTo: this.filterOffenceDateTo ? (moment(this.filterOffenceDateTo)).format('YYYYMMDD') : '',
              trialSetIndicator: this.trialSetIndicator,
              trialDateFrom: this.filterTrialDateFrom ? (moment(this.filterTrialDateFrom)).format('YYYYMMDD') : '',
              trialDateTo: this.filterTrialDateTo ? (moment(this.filterTrialDateTo)).format('YYYYMMDD') : '',
            }

            this.filteredTickets = [];
          }

          this.errorMessage = "";
          this.isDataLoaded = false;

          this.spinner.show();

          this.intakeService.findTicketsAsync(this.ticketDashboardSearch).subscribe(
            (result: any) => {
            
              if (Array.from(result.items).length > 0) {
                this.filteredTickets = this.filteredTickets.concat(Array.from(result.items));

                // setup cbId's
                this.filteredTickets.forEach( t => {
                  t.cbId = 'cb'+t.ticketNumber;
                });

                // set offset
                this.ticketDashboardSearch.offset = this.filteredTickets[this.filteredTickets.length - 1].dbKey;
              }

              this.isDataLoaded = true;
              this.changeDetectorRef.detectChanges();
              this.spinner.hide();
            },
            (error) => {
              this.errorMessage = "Problem loading the tickets.";
              this.filteredTickets = [];
              this.isDataLoaded = true;              
              this.changeDetectorRef.detectChanges();

              this.spinner.hide();
            }
          );
        } 
      })
    }
  }

  saveSearchFilters(){
    var searchFilters = this.dashboardSearch;

    searchFilters.noticeNumber = this.noticeNumber;
    searchFilters.policeFileNumber = this.policeFileNumber;
    searchFilters.issuingOfficerRegimentalNumber = this.issuingOfficerRegimentalNumber;
    searchFilters.recipientName = this.recipientName;
    searchFilters.apisStatuses = JSON.stringify(this.selectedContraventionSeizureStatus);
    searchFilters.safeRoadsStatuses = JSON.stringify(this.selectedSafeRoadsStatuses);
    searchFilters.fromDate = this.filterStartDate!==null && this.filterStartDate?.toString().length > 0 ? (moment(this.filterStartDate)).format('YYYY-MM-DD') : "",
    searchFilters.toDate = this.filterEndDate!==null && this.filterEndDate?.toString().length > 0 ? (moment(this.filterEndDate)).format('YYYY-MM-DD') : "",
    
    this.intakeService.saveSearchFilters(searchFilters);
  }
  
  setPageNumbers(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    if (+this.totalPages === 0) {
      this.totalPages = 1;
      this.pageNumbers = [1];
      return;
    }

    let totalPageRanges = Math.ceil(this.totalPages / this.pageRange);        
    let totalPagesAsArray = Array(this.totalPages).fill(1).map((value: any, index: number) => value + index);
    let totalPageRangesAsArray = Array(totalPageRanges).fill(0).map((value: any, index: number) => value + index);    
    let pageRangeIndex = totalPageRangesAsArray.find((value: any, index: number) => this.pageNumber <= (value * this.pageRange) + this.pageRange);
    
    this.isLastRange = totalPageRangesAsArray.length - 1 == pageRangeIndex;
    this.pageNumbers = totalPagesAsArray.slice(pageRangeIndex * this.pageRange, (pageRangeIndex * this.pageRange) + this.pageRange);                
  }

  getStatusCssClass(napStatus: string): string {
    switch (napStatus) {
      case 'Details Required': return 'fas fa-exclamation-triangle nap-warning';
      case 'Documents Required': case 'Disclosure Pending': case "Court Docs Pending": return 'fas fa-file nap-warning';
      case 'Submitted': case 'Disclosure Completed': return 'fas fa-check-circle nap-success';
      case 'Cancelled': return 'fas fa-times nap-error';
    }

    return '';
  } 

  getTicketStatusCssClass(ticketView: TicketViewModel): string {
    if (ticketView.ticketWithdrawalStatus.length > 0 && ticketView.ticketWithdrawalStatus != 'Unavailable' && ticketView.ticketWithdrawalStatus != 'Rejected')
    {
      return 'fas fa-times nap-error';
    }
    else
      return this.getStatusCssClass(ticketView.status);    
  } 

  getTicketStatus(ticketView: TicketViewModel): string {
    return ticketView.ticketWithdrawalStatus.length > 0 && ticketView.ticketWithdrawalStatus != 'Unavailable' && ticketView.ticketWithdrawalStatus != 'Rejected'? `Cancellation - ${ticketView.ticketWithdrawalStatus}` : ticketView.status;
  }

  getContraventionStatusCssClass(contraventionStatus: string): string {
    switch (contraventionStatus) {
      case 'Cancelled': return 'contravention-status-cancelled';
    }

    return 'contravention-status-normal'; 
  }

  onSortIconClick(propertyName: string): void {  
    let column = this.columns.find(c => c.propertyName == propertyName);
    column.ascending = !column.ascending;

    this.sortDirection = "desc";
    if (column.ascending) {
      this.sortDirection = "asc";
    }

    this.orderBy = propertyName;
    this.setFilteredNaps();        
  }  

  onPageSizeChange(ev: any): void {
    let target = ev.target as HTMLSelectElement;    
    this.pageSize = parseInt(target.selectedOptions[0].value);
    this.pageNumber = 1;

    this.setWindowHistory();
    this.setFilteredNaps();
  }

  onSearchButtonClick(): void {  
    this.searchValidationError = '';

    if (this.ticketTypeId == TicketTypes.Violations) {
      if ((this.violationTicketNumber == null || this.violationTicketNumber?.trim() == '') 
        && (this.filterOffenceDateFrom == null || this.filterOffenceDateFrom == '' || this.filterOffenceDateFrom == 'Invalid Date' 
            || this.filterOffenceDateTo == null || this.filterOffenceDateTo == '' || this.filterOffenceDateTo == 'Invalid Date')
        && (this.filterTrialDateFrom == null || this.filterTrialDateFrom == '' || this.filterTrialDateFrom == 'Invalid Date' 
            || this.filterTrialDateTo == null || this.filterTrialDateTo == '' || this.filterTrialDateTo == 'Invalid Date')
        && (this.officerBadgeNumber == null || this.officerBadgeNumber?.trim() == '')) {
        this.searchValidationError = 'Please enter either the Ticket Number, Issuing Officer, Offence Date Range or Trial Date range to proceed.';
        return;
      }
    }

    this.pageNumber = 1;

    this.setWindowHistory();
    this.saveSearchFilters();
    this.setFilteredNaps();
  }

  onLoadMoreClick() {
    this.setFilteredNaps(true);
  }

  public resetFiltersClick(ev: any): void {

    if (!this.isResetEnabled) {
      return;
    }    
    this.clearFilters();
    this.setWindowHistory();
    this.setFilteredNaps();  
    this.isResetEnabled = false;
  }
  
  public onFiltersChanged(ev: any) {
    this.isResetEnabled = !this.isFiltersEmpty()
  }

  public onTrialScheduledChanged()
  {
    //Reset trial date search if "No Trial Set" option is selected
    if (this.trialSetIndicator == 'N')
    {
      this.filterTrialDateFrom = '';
      this.filterTrialDateTo = '';
    }

    this.isResetEnabled = !this.isFiltersEmpty();
  }

  public onCheckboxSelect(ev: any) {
    this.isAllSelected = ev.target.checked;
    for (var i = 0; i < this.filteredNaps.length; i++) {
      this.filteredNaps[i].isSelected = this.isAllSelected;
    }
  }

  public onViolationSelectAll(ev: any) {
    this.isAllSelected = ev.target.checked;
    for (var i = 0; i < this.filteredTickets.length; i++) {
      this.filteredTickets[i].isSelected = this.isAllSelected;
    }
  }

  public onNapCheckBoxClick(ev: any, nap: NapViewModel) {
    if (!this.isSuperUser)
      return;

    if (!nap.isSeizure) {
      let stopNaps = this.filteredNaps.filter(n=>n.stopInformationId===nap.stopInformationId);
      stopNaps.forEach( n=> {
        let ct = this.localStorageService.getContraventionTypes().find(x => x.id === +n.contraventionTypeId);
        if (n.isSeizure && ct.isIRS && !n.isNoticeCancellationRequestSubmitted) {
          var checkbox = document.getElementById(n.cbId) as HTMLInputElement | null;
          if (checkbox!=null) {
            if (ev.target.checked) {
              checkbox.disabled = true;
              checkbox.checked = false;
              n.isSelected = false;
            } else {
              checkbox.disabled = false;
            }
          }
        }
      });
    }
  }

  violationItemsSelected(): number {
    return this.filteredTickets.filter(x => x.isSelected).length;
  }

  getSelectedTickets(): TicketViewModel[] {
    return this.filteredTickets.filter(x=>x.isSelected);
  }

  itemsSelected(): number {
    return this.filteredNaps.filter(x => x.isSelected).length;
  }

  getSelectedNaps(): NapViewModel[] {
    return this.filteredNaps.filter(x=>x.isSelected);
  }

  getEligibleSelections(): number {
    let filterCount = this.filteredNaps.filter(x=>x.isSelected &&
          !x.isNoticeCancellationRequestSubmitted &&
          x.contraventionStatusTypeName != SafeRoadsStatus.Cancelled).length;
    return filterCount;
  }

  getSelectedCancelledNapsCount(): number {
    let filterCount = this.filteredNaps.filter(x=>x.isSelected && 
          x.isNoticeCancellationRequestSubmitted && 
          x.noticeCancellationRequestDecisionTypeId != RequestDecisionTypes.Denied &&
          x.contraventionStatusTypeName == SafeRoadsStatus.Cancelled).length;
    return filterCount;
  }

  public isFiltersEmpty(): boolean {  

    var searchFilters = this.intakeService.getSearchFilters();

    if (searchFilters != null) {
      return false;
    }

    if (
      this.selectedContraventionSeizureStatus.length===0 && 
      this.selectedSafeRoadsStatuses.length===0 && 
      this.noticeNumber.length===0 &&
      this.policeFileNumber.length===0 &&
      this.recipientName.length===0 &&
      this.issuingOfficerRegimentalNumber.length===0 &&
      this.filterStartDate === null &&
      this.filterEndDate === null &&
      this.violationTicketNumber.length===0 &&
      this.accusedFirstName.length===0 &&
      this.accusedLastName.length===0 &&
      this.trialSetIndicator.length === 0) {
      return true;
    }

    return false;
  }

  public onPreviousLinkClick(ev: any): void {  
    if (ev.target.classList.contains("disabled")) {
      return;
    }
    
    this.pageNumber -= 1;    
    if (+this.pageNumber <= 1) {
      this.pageNumber = 1;
    }

    this.setWindowHistory();        
    this.setFilteredNaps();
  }

  public onNextLinkClick(ev: any): void {
    if (ev.target.classList.contains("disabled")) {
      return;
    }
  
    this.pageNumber += 1;
  
    if (+this.pageNumber >= this.totalPages) {
      this.pageNumber = this.totalPages;     
    }
    
    this.setWindowHistory();    
    this.setFilteredNaps();
  }

  onPageNumberClick(ev: any, pageNumber: number): void {
    if (ev.target.classList.contains("selected")) {
      return;
    }
        
    this.pageNumber = pageNumber;

    this.setWindowHistory();                
    this.setFilteredNaps();
  }

  onSubmitClick(isValid: boolean, routeLink: string)
  {
    this.isSubmitClicked = true;
    if (isValid) {
      this.router.navigateByUrl(routeLink);
    } 
    else
      window.scroll(0,0);  
  }

  showErrors(error: any) {
    if (error?.status == 400 && error?.error && Array.isArray(error?.error))
        this.errorMessage = error.error[0].error;
    else if (error?.status == 400 && (typeof error?.error === 'string' || error?.error instanceof String))
        this.errorMessage = error.error;
    else if (error?.status == 400 && (typeof error?.error?.error === 'string' || error?.error?.error instanceof String))
        this.errorMessage = error.error.error;
    else    
        this.errorMessage = "Something went wrong while calling MOVES service. Please try again later.";
  }

  onSelectMatch()
  {
    // const selectedNodes = this.agGrid.api.getSelectedNodes();
    // if (selectedNodes.length > 0)
    // {
    //   //this.autoFillMovesInfo(selectedNodes[0].data)
    //   //this.showHideModal(false);
    // }
  }

  onNoticeNumberClick(stopInformationId: string, apisStatus: string, issuingOfficerEmail: string)
  {
    this.spinner.show();

    this.intakeService.getStopInformationByIdAsync(stopInformationId)
      .subscribe((stopInformation: StopInformation) => {
        stopInformation.isNoticePrinted = true;
        this.intakeService.createStopInformationContext(stopInformation);
        this.spinner.hide();

        if (stopInformation.submissionVersion == Constants.SubmissionVersion.PHASE1)
          this.router.navigateByUrl(apisStatus == "Cancelled"?`/contravention/add-details/review-confirm`:`/contravention/add-details/supporting-documents`);
        else {
          const localStopInformation = this.intakeService.getStopInformationModel();
          const userIsIssuingOfficer = issuingOfficerEmail === this.user.userName;
          localStopInformation.apisStatus = apisStatus;
          this.intakeService.saveStopInformationContext();

          switch (apisStatus) {
            case "Cancelled":
              this.router.navigateByUrl(`/contravention/add-details/review-confirm`);
              break;
            case "Details Required":
              if (userIsIssuingOfficer && !this.isDataEntryOrSupervisor) {
                localStopInformation.userIsAddingDetails = true;
                this.intakeService.saveStopInformationContext();

                // JTI-4995 - If a stop's IRS contravention is cancelled, but the SDP seizure is still active, show SDP seizure details
                if (localStopInformation.contraventions.length > 0 && !localStopInformation.isOnlySDPActive) {
                  this.router.navigateByUrl(`/contravention/add-details/contravention-details`);
                }
                else {
                  this.router.navigateByUrl(`/contravention/add-details/sdp-notice-details`);
                }
              }
              else {
                this.router.navigateByUrl(`/contravention/add-details/supporting-documents`);
              }
              break;
            case "Submitted":
            case "Documents Required":
              this.router.navigateByUrl(`/contravention/add-details/supporting-documents`);
              break;
            default:
              break;
          }
        }
      },
      (error: any) => {
        this.errorMessage = "Something went wrong";
        this.spinner.hide(); 
      }
    );
  }

  onCancelMultipleNoticesClick() {
    // modal dialog        
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(CancelMultipleContraventionsModalComponent);

    //Create notice cancellation request 
    var noticeCancellationRequest = new NoticeCancellationRequest ({
      requestStatusTypeId: RequestStatusTypes.New,
      requestingOfficerId: this.user.userId
    });

    this.getSelectedNaps().forEach(nap => {
      var noticeCancellationRequestMapping = new NoticeCancellationRequestMapping();
      if (nap.isSeizure)
      {
        noticeCancellationRequestMapping.vehicleSeizureId = nap.vehicleSeizureId;
        noticeCancellationRequestMapping.vehicleSeizure = nap;
      }
      else
      {
        noticeCancellationRequestMapping.contraventionId = nap.contraventionId;
        noticeCancellationRequestMapping.contravention = nap;
      }
      noticeCancellationRequestMapping.recordNumber = nap.contraventionNumber;
      noticeCancellationRequest.noticeCancellationRequestMappings.push(noticeCancellationRequestMapping);
    });
    
    componentRef.instance.noticeCancellationRequest = Object.assign({}, noticeCancellationRequest);
    componentRef.instance.close.subscribe((result: boolean) => {
      if (result) {
        // refresh the list
        this.setFilteredNaps();
      }        
      componentRef.destroy();
    });    
  }

  onCancelMultipleTicketsClick() {
    // modal dialog        
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(WithdrawTrafficTicketsModalComponent);

    //Create traffic ticket withdrawal request 
    var ticketWithdrawalRequest = new TicketWithdrawalRequest ({
      requestingOfficerEmail: this.user.emailAddress,
      requestingOfficerName: this.user.displayName
    });

    ticketWithdrawalRequest.tickets = this.getSelectedTickets();

    componentRef.instance.ticketWithdrawalRequest = Object.assign({}, ticketWithdrawalRequest);
    componentRef.instance.close.subscribe((result: boolean) => {
      if (result) {
        // refresh the list
        this.setFilteredNaps();
      }        
      componentRef.destroy();
    });    
  }

  showTicketWithdrawModal(ticket: TicketViewModel) {
    // modal dialog        
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(WithdrawTrafficTicketsModalComponent);

    //Create traffic ticket withdrawal request 
    var ticketWithdrawalRequest = new TicketWithdrawalRequest ({
      requestingOfficerEmail: this.user.emailAddress,
      requestingOfficerName: this.user.displayName
    });

    ticketWithdrawalRequest.tickets.push(ticket);

    componentRef.instance.ticketWithdrawalRequest = Object.assign({}, ticketWithdrawalRequest);
    componentRef.instance.close.subscribe((result: boolean) => {
      if (result) {
        // refresh the list
        this.setFilteredNaps();
      }        
      componentRef.destroy();
    });    
  }

  getNapCancellationRequestStatus(nap: NapViewModel)
  {
    var status = "Cancellation Request";
    switch (+nap.noticeCancellationRequestDecisionTypeId) {
      case RequestDecisionTypes.Approved:
        status += " Approved";
        break;
      case RequestDecisionTypes.Denied:
        status += " Denied";
        break;
      default:
        status += " Pending";
        break;
    }

    return status;
  }

  onTicketNumberClick(ticketView: TicketViewModel)
  {
    this.spinner.show();

    this.ticketService.getDisclosureDocuments(ticketView.ticketNumber)
      .subscribe((disclosureDocuments: DisclosureDocument[]) => {
        this.spinner.hide();

        this.ticketService.resetSharedDisclosureDocumentsUploadRequestContext();
        var sharedDocumentsUploadRequest = this.ticketService.getSharedDisclosureDocumentsUploadRequestContext();
        sharedDocumentsUploadRequest.ticketNumbers = [ticketView.ticketNumber];
        sharedDocumentsUploadRequest.ticketView = ticketView;
        sharedDocumentsUploadRequest.disclosureDocuments = disclosureDocuments;

        //Save request context
        this.ticketService.setSharedDisclosureDocumentsUploadRequestContext();
        this.router.navigateByUrl(`/ticket/disclosure/upload-disclosures`);
      },
      (error: any) => {
        this.errorMessage = "Something went wrong";
        this.spinner.hide(); 
      }
    );  
  }

  onAddViolationDocumentsClick()
  {
    if (this.violationItemsSelected() <= 0) return;

    //Create shared document upload request 
    this.ticketService.resetSharedDisclosureDocumentsUploadRequestContext();
    var sharedDocumentsUploadRequest = this.ticketService.getSharedDisclosureDocumentsUploadRequestContext();
    sharedDocumentsUploadRequest.ticketNumbers = this.getSelectedTickets().map(t => t.ticketNumber);

    //Save request context
    this.ticketService.setSharedDisclosureDocumentsUploadRequestContext();
    this.router.navigateByUrl(`/ticket/disclosure/upload-disclosures`);  
  }

  onTabHeaderClick(ev: any): void {        
    if (ev.target.classList.contains("selected")) {
      return;
    }
   
    let tabId = ev.target.id.split("_")[1];

    $(".tab-header-item").removeClass("selected");
    $(`#tabHeader_${tabId}`).addClass("selected");
    
    switch (tabId) {
      case "ContraventionsAndSeizures":
        this.ticketTypeId = TicketTypes.ContraventionsAndSeizures;
        break;
      case "Violations":
        this.ticketTypeId = TicketTypes.Violations;
        this.ticketService.resetSharedDisclosureDocumentsUploadRequestContext();
        if (this.filteredTickets?.length == 0) //Avoid unnecessary auto searches
          this.onSearchButtonClick();
        break;
      default:
        this.ticketTypeId = null;
        break;
    }
  }

  validateLocations(locationNames: LocationType[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = locationNames.findIndex(x => {
        return (new RegExp('\^' + x.name + '\$')).test(control.value?.name);
      });
      return index < 0 ? { 'forbiddenLocations': { value: control.value } } : null;
    };
  } 

  displayLocation(subject){
    return subject? subject.name : '';
  }

  isMultipleTicketCancellationAllowed()
  {
    return this.getSelectedTickets().filter(x => x.isTicketCancellationRestricted || x.outstandingWarrantIndicator=='Y').length <= 0;
  }

  isMultipleTicketDisclosureAllowed()
  {
    return this.getSelectedTickets().filter(x => x.isTicketUnderCancellation).length <= 0;
  }

  private _filterLocation(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.locationTypes.filter(option => 
      option.name.toLowerCase().includes(filterValue));
  }
}

