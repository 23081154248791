<form #PrintAndIssueForm="ngForm">
    <div class="health-message">{{ticketsHealthStatusMessage}}</div>
    <div class="d-flex flex-column">
        <h1>Print & Issue</h1>
        <div class="card mt-4">
            <div class="card-header">
                <div>{{stopInformation.isEmergencySubmission || stopInformation.isPaperSubmission?"Document(s) to Submit to APIS":"Document(s) to be printed"}}</div>
            </div>
            <div class="card-body">
                <div *ngFor="let contravention of contraventions | orderBy:sortedContraventions; let i = index;">
                    <div>
                        <div class="heading-underline" *ngIf="i>0"></div>
                        <div class="form-group row">
                            <div class="col-lg-12" *ngIf="isIRS(contravention.contraventionTypeId)">
                                NOTICE OF ADMINISTRATIVE PENALTY
                            </div>
                            <div class="col-lg-12" *ngIf="isSDP(contravention.contraventionTypeId) && !contravention.isNoVehicleSeizureMade">
                                SUSPENDED DRIVER VEHICLE SEIZURE PROGRAM SEIZURE NOTICE
                            </div>
                            <div class="col-lg-12" *ngIf="!isIRS(contravention.contraventionTypeId) && !isNonTSASDP(contravention.contraventionTypeId) && availableTSA && contraventions.length > 1">
                                VIOLATION TICKET #{{getTicketIndex(i)}}
                            </div>
                            <div class="col-lg-12" *ngIf="!isIRS(contravention.contraventionTypeId) && !isNonTSASDP(contravention.contraventionTypeId) && availableTSA && contraventions.length == 1">
                                VIOLATION TICKET
                            </div>
                        </div>
                    </div>
                   <div class="form-group">
                        <div class="row">
                            <div class="col-lg-12">
                                <label>{{getContraventionTypeDescription(contravention.contraventionTypeId)}}</label>
                            </div>
                        </div>
                        <div class="row" *ngIf="contravention.secondaryContraventionTypeId">
                            <div class="col-lg-12">
                                <label>{{getContraventionTypeDescription(contravention.secondaryContraventionTypeId)}}</label>
                            </div>
                        </div>
                        <div class="row" *ngIf="contravention.tertiaryContraventionTypeId">
                            <div class="col-lg-12">
                                <label>{{getContraventionTypeDescription(contravention.tertiaryContraventionTypeId)}}</label>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!isIRSorSDP(contravention.contraventionTypeId) && availableTSA">
                        <div class="form-group row">
                            <div class="col-sm-5">
                                <label for="contraventionFine{{i}}">Contravention Fine Payment</label>
                                <input readonly type="text" class="form-control" id="contraventionFine{{i}}" name="contraventionFine{{i}}" value="{{ contravention.contraventionTypeId == 0  ? '' : '$' + contravention.fine.fineAmount + (contravention.fine.fineAmount == 0? '': ' (Plus Victim Fine Surcharge)')}}">
                            </div>
                            <div class="col-sm-3">
                                <label for="contraventionDemerits{{i}}">Demerits</label>
                                <input readonly type="text" class="form-control" id="contraventionDemerits{{i}}" name="contraventionDemerits{{i}}" value="{{ contravention.demerits }}">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-8" *ngIf="isOffenceTypeEditable(contravention); else offenceTypeNotEditable">
                                <label for="offenceType{{i}}">Offence Type <span class="label-hint">(Required)</span></label>
                                <select [appSelectValidator]="true" #offenceTypeControl="ngModel" class="form-control" name="offenceType{{i}}" id="offenceType{{i}}" 
                                [(ngModel)]="contravention.offenceTypeId" (ngModelChange)="onOffenceTypeChange(contravention)"
                                [ngClass]="{'is-invalid':(offenceTypeControl.touched || isSubmitClicked) && offenceTypeControl.errors?.defaultSelected}">
                                    <option value=0>Select an Offence Type</option>
                                    <option *ngFor="let offenceType of offenceTypes" [value]="offenceType.id">{{offenceType.name}}</option>
                                </select>
                                <div class="invalid-feedback">
                                    <div>Offence Type is required</div>
                                </div>
                            </div>
                            <ng-template #offenceTypeNotEditable>
                                <div class="col-sm-8">
                                    <label for="offenceType{{i}}">Offence Type</label>
                                    <input readonly type="text" class="form-control" id="offenceTypeReadonly{{i}}" name="offenceTypeReadonly{{i}}" [value]="localStorageService.getTypeItemDescriptionById(contravention.offenceTypeId, TypeTable.OffenceType)">
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="(paperSubmissionOptionId == PaperSubmissionOptions.Emergency || paperSubmissionOptionId == PaperSubmissionOptions.EmergencyAndPaper) && isEmergencySubmissionPossible && isIRS(contravention.contraventionTypeId) && contravention.contraventionTypeId == ContraventionTypes.IRS24 && contraventions.length == 1">
                        <div class="form-group row">
                            <div class="col-12">
                                <label>I already issued this IRS 24 using an emergency form:</label>        
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input required type="radio" class="custom-control-input" id="emergencyIRSYes" name="emergencyIRS" #emergencyIRSYesControl="ngModel" [value]="true" [(ngModel)]="stopInformation.isEmergencySubmission" (change)="resetIssueDate()" [ngClass]="{'is-invalid': stopInformation.isEmergencySubmission==null && (emergencyIRSYesControl.touched || emergencyIRSNoControl.touched || isSubmitClicked)}">
                                        <label class="custom-control-label" for="emergencyIRSYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input required type="radio" class="custom-control-input" id="emergencyIRSNo" name="emergencyIRS" #emergencyIRSNoControl="ngModel" [value]="false" [(ngModel)]="stopInformation.isEmergencySubmission" (change)="resetIssueDate()" [ngClass]="{'is-invalid': stopInformation.isEmergencySubmission==null && (emergencyIRSYesControl.touched || emergencyIRSNoControl.touched || isSubmitClicked)}">
                                        <label class="custom-control-label" for="emergencyIRSNo">No</label>
                                    </div>
                                    <input required type="radio" style="display:none" id="emergencyIRSHidden" name="emergencyIRS" #emergencyIRSHiddenControl="ngModel" [value]="null" [(ngModel)]="stopInformation.isEmergencySubmission" [ngClass]="{'is-invalid': stopInformation.isEmergencySubmission==null && (emergencyIRSYesControl.touched || emergencyIRSNoControl.touched || isSubmitClicked)}">
                                    <div class="invalid-feedback">
                                        <div>Selection is required</div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                        <div class="form-group row" *ngIf="stopInformation.isEmergencySubmission">
                            <div class="col-12 col-sm-6">
                                <label for="contraventionNumber">Contravention Number <span class="label-hint">(Required)</span></label>
                                <input required appEmergencyContraventionNumberValidator=true appContraventionNumberDuplicateValidator #contraventionNumberControl="ngModel" minlength="10" maxlength="10" type="text" class="form-control uppercase" id="contraventionNumber" name="contraventionNumber" placeholder="F--------O" [(ngModel)]="contravention.contraventionNumber" [ngClass]="{'is-invalid': (contraventionNumberControl.touched || isSubmitClicked) && contraventionNumberControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="contraventionNumberControl.errors?.required">Contravention Number is required</div>
                                    <div *ngIf="contraventionNumberControl.errors?.invalidContraventionNumber && !contraventionNumberControl.errors?.required">Invalid Contravention Number</div>
                                    <div *ngIf="contraventionNumberControl.errors?.duplicateContraventionNumber && !contraventionNumberControl.errors?.invalidContraventionNumber && !contraventionNumberControl.errors?.required">Contravention Number already exists</div>
                                </div>
                            </div>
                            <div class="col-lg-6" *ngIf="!contravention.isNoVehicleSeizureMade && contravention.vehicleSeizure">
                                <label for="seizureNumber">IRS Seizure Number <span class="label-hint">(Required)</span></label>
                                <input required appEmergencySeizureNumberValidator=true appSeizureNumberDuplicateValidator  #seizureNumberControl="ngModel" minlength="17" maxlength="17" type="text" class="form-control" id="seizureNumber" name="seizureNumber"  placeholder="10000------------" [(ngModel)]="contravention.vehicleSeizure.seizureNumber" [ngClass]="{'is-invalid': (seizureNumberControl.touched || isSubmitClicked) && seizureNumberControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="seizureNumberControl.errors?.required">IRS Seizure Number is required</div>
                                    <div *ngIf="seizureNumberControl.errors?.invalidSeizureNumber && !seizureNumberControl.errors?.required">Invalid IRS Seizure Number</div>
                                    <div *ngIf="seizureNumberControl.errors?.duplicateSeizureNumber && !seizureNumberControl.errors?.invalidSeizureNumber && !seizureNumberControl.errors?.required">Seizure Number already exists</div>
                                </div>
                            </div>
                        </div>    
                    </ng-container>

                    <ng-container *ngIf="(paperSubmissionOptionId == PaperSubmissionOptions.Paper || paperSubmissionOptionId == PaperSubmissionOptions.EmergencyAndPaper) && isPaperSubmissionPossible && isIRS(contravention.contraventionTypeId) && contraventions.length <= 2">
                        <div class="form-group row">
                            <div class="col-12">
                                <label *ngIf="contraventions.length < 2" class="text-danger">I already issued this IRS Notice using a PAPER FORM</label>
                                <label *ngIf="contraventions.length >= 2" class="text-danger">I already issued this IRS Notice & SDP Seizure Notice using PAPER FORMS</label>
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input required type="radio" class="custom-control-input" id="paperNoticeYes" name="paperNotice" #paperNoticeYesControl="ngModel" [value]="true" [(ngModel)]="stopInformation.isPaperSubmission" (change)="resetIssueDate()" [ngClass]="{'is-invalid': stopInformation.isPaperSubmission==null && (paperNoticeYesControl.touched || paperNoticeNoControl.touched || isSubmitClicked)}">
                                        <label class="custom-control-label" for="paperNoticeYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input required type="radio" class="custom-control-input" id="paperNoticeNo" name="paperNotice" #paperNoticeNoControl="ngModel" [value]="false" [(ngModel)]="stopInformation.isPaperSubmission" (change)="resetIssueDate()" [ngClass]="{'is-invalid': stopInformation.isPaperSubmission==null && (paperNoticeYesControl.touched || paperNoticeNoControl.touched || isSubmitClicked)}">
                                        <label class="custom-control-label" for="paperNoticeNo">No</label>
                                    </div>
                                    <input required type="radio" style="display:none" id="paperNoticeHidden" name="paperNotice" #paperNoticeHiddenControl="ngModel" [value]="null" [(ngModel)]="stopInformation.isPaperSubmission" [ngClass]="{'is-invalid': stopInformation.isPaperSubmission==null && (paperNoticeYesControl.touched || paperNoticeNoControl.touched || isSubmitClicked)}">
                                    <div class="invalid-feedback">
                                        <div>Selection is required</div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                        <div class="form-group row" *ngIf="stopInformation.isPaperSubmission">
                            <div class="col-12 col-sm-6">
                                <label for="contraventionNumber">Contravention Number <span class="label-hint">(Required)</span></label>
                                <input required appPaperContraventionNumberValidator=true appContraventionNumberDuplicateValidator #contraventionNumberControl="ngModel" minlength="10" maxlength="10" type="text" class="form-control uppercase" id="contraventionNumber" name="contraventionNumber" placeholder="Enter contravention # from printed form" [(ngModel)]="contravention.contraventionNumber" [ngClass]="{'is-invalid': (contraventionNumberControl.touched || isSubmitClicked) && contraventionNumberControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="contraventionNumberControl.errors?.required">Contravention Number is required</div>
                                    <div *ngIf="contraventionNumberControl.errors?.invalidContraventionNumber && !contraventionNumberControl.errors?.required">Invalid Contravention Number</div>
                                    <div *ngIf="contraventionNumberControl.errors?.oldContraventionNumber && !contraventionNumberControl.errors?.invalidContraventionNumber && !contraventionNumberControl.errors?.required">Invalid form. Re-issue with Rev. 2023-01 form</div>
                                    <div *ngIf="contraventionNumberControl.errors?.duplicateContraventionNumber && !contraventionNumberControl.errors?.oldContraventionNumber && !contraventionNumberControl.errors?.invalidContraventionNumber && !contraventionNumberControl.errors?.required">Contravention Number already exists</div>
                                </div>
                            </div>
                            <div class="col-lg-6" *ngIf="!contravention.isNoVehicleSeizureMade && contravention.vehicleSeizure">
                                <label for="seizureNumber">IRS Seizure Number <span class="label-hint">(Required)</span></label>
                                <input required appSeizureNumberValidator=true appSeizureNumberDuplicateValidator #seizureNumberControl="ngModel" minlength="17" maxlength="17" type="text" class="form-control" id="seizureNumber" name="seizureNumber"  placeholder="Enter seizure # from printed form" [(ngModel)]="contravention.vehicleSeizure.seizureNumber" [ngClass]="{'is-invalid': (seizureNumberControl.touched || isSubmitClicked) && seizureNumberControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="seizureNumberControl.errors?.required">IRS Seizure Number is required</div>
                                    <div *ngIf="seizureNumberControl.errors?.invalidSeizureNumber && !seizureNumberControl.errors?.required">Invalid IRS Seizure Number</div>
                                    <div *ngIf="seizureNumberControl.errors?.duplicateSeizureNumber && !seizureNumberControl.errors?.invalidSeizureNumber && !seizureNumberControl.errors?.required">Seizure Number already exists</div>
                                </div>
                            </div>
                        </div>    
                    </ng-container>

                    <ng-container *ngIf="(paperSubmissionOptionId == PaperSubmissionOptions.Paper || paperSubmissionOptionId == PaperSubmissionOptions.EmergencyAndPaper) && isPaperSubmissionPossible && isSDP(contravention.contraventionTypeId) && contraventions.length == 1 && !contravention.isNoVehicleSeizureMade">
                        <div class="form-group row">
                            <div class="col-12">
                                <label class="text-danger">I already issued this SDP seizure notice using a PAPER FORM</label>        
                                <div class="mt-2">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input required type="radio" class="custom-control-input" id="paperNoticeYes" name="paperNotice" #paperNoticeYesControl="ngModel" [value]="true" [(ngModel)]="stopInformation.isPaperSubmission" (change)="resetIssueDate()" [ngClass]="{'is-invalid': stopInformation.isPaperSubmission==null && (paperNoticeYesControl.touched || paperNoticeNoControl.touched || isSubmitClicked)}">
                                        <label class="custom-control-label" for="paperNoticeYes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input required type="radio" class="custom-control-input" id="paperNoticeNo" name="paperNotice" #paperNoticeNoControl="ngModel" [value]="false" [(ngModel)]="stopInformation.isPaperSubmission" (change)="resetIssueDate()" [ngClass]="{'is-invalid': stopInformation.isPaperSubmission==null && (paperNoticeYesControl.touched || paperNoticeNoControl.touched || isSubmitClicked)}">
                                        <label class="custom-control-label" for="paperNoticeNo">No</label>
                                    </div>
                                    <input required type="radio" style="display:none" id="paperNoticeHidden" name="paperNotice" #paperNoticeHiddenControl="ngModel" [value]="null" [(ngModel)]="stopInformation.isPaperSubmission" [ngClass]="{'is-invalid': stopInformation.isPaperSubmission==null && (paperNoticeYesControl.touched || paperNoticeNoControl.touched || isSubmitClicked)}">
                                    <div class="invalid-feedback">
                                        <div>Selection is required</div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isSDP(contravention.contraventionTypeId) && stopInformation.isPaperSubmission">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="sdpSeizureNumber">SDP Seizure Number <span class="label-hint">(Required)</span></label>
                                    <input required appSeizureNumberValidator=true appSeizureNumberDuplicateValidator #sdpSeizureNumberControl="ngModel" minlength="17" maxlength="17" type="text" class="form-control" id="sdpSeizureNumber" name="sdpSeizureNumber"  placeholder="Enter seizure # from printed form" [(ngModel)]="contravention.vehicleSeizure.seizureNumber" [ngClass]="{'is-invalid': (sdpSeizureNumberControl.touched || isSubmitClicked) && sdpSeizureNumberControl.invalid}">
                                    <div class="invalid-feedback">
                                        <div *ngIf="sdpSeizureNumberControl.errors?.required">SDP Seizure Number is required</div>
                                        <div *ngIf="sdpSeizureNumberControl.errors?.invalidSeizureNumber && !sdpSeizureNumberControl.errors?.required">Invalid SDP Seizure Number</div>
                                        <div *ngIf="sdpSeizureNumberControl.errors?.duplicateSeizureNumber && !sdpSeizureNumberControl.errors?.invalidSeizureNumber && !sdpSeizureNumberControl.errors?.required">SDP Seizure Number already exists</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="stopInformation.isEmergencySubmission != true && stopInformation.isPaperSubmission != true && isIRS(contravention.contraventionTypeId) && (contravention.vehicleSeizure || isRoadsideAppealRequired(contravention))">
                        <div class="heading-underline"></div>
                        <div class="form-group">
                            <div class="row mb-2">
                                <div class="col-lg-12">
                                    OTHER DOCUMENTS:
                                </div>
                            </div>
                            <div class="row" *ngIf="contravention.vehicleSeizure">
                                <div class="col-lg-12">
                                    <Label class="mb-0">IRS Seizure Notice</Label>
                                </div>
                            </div>
                            <div class="row" *ngIf="isRoadsideAppealRequired(contravention)">
                                <div class="col-lg-12">
                                    <label class="mb-0">Roadside Appeal Form</label>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isIRS(contravention.contraventionTypeId)">
                        <div class="container d-flex justify-content-left">
                            <div class="row callout">
                                <div class="col-md-1 callout-logo">   
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                    </svg>
                                </div>
                                <div class="col-md-11 mt-4 mb-4">   
                                    <h5>Reasonable Grounds to Believe <span class="label-hint">(Required)</span></h5>
                                    <div class="form-check mt-2">
                                        <div class="custom-control custom-checkbox pl-1">
                                            <input required #chkResonableGrounds="ngModel" type="checkbox" class="custom-control-input" id="chkResonableGrounds" name="chkResonableGrounds" [(ngModel)]="isReasonableGroundsToBelieve" [ngClass]="{'is-invalid': (chkResonableGrounds.touched || isSubmitClicked) && chkResonableGrounds.invalid}">
                                            <label class="custom-control-label section-label" for="chkResonableGrounds">
                                                By checking this box, the undersigned officer has reasonable grounds to believe that the recipient named in this Notice of Administrative Penalty did contravene the section of the <span class="italic-text">Traffic Safety Act</span> specified herein.
                                            </label>
                                            <div class="invalid-feedback">
                                                <div>Reasonable Grounds to Believe must be checked</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="mb-4" *ngIf="isNonTSASDP(contravention.contraventionTypeId) || (isSDP(contravention.contraventionTypeId) && !availableTSA)">
                        <div class="container d-flex justify-content-left">
                            <div class="row callout">
                                <div class="col-md-1 callout-logo">   
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                    </svg>
                                </div>
                                <div class="col-md-11 mt-4 mb-4">   
                                    <h5>Issue Seizure Notice only through APIS</h5>
                                    <div class="mt-2">
                                        You must issue a <span *ngIf="!availableTSA" class="bold-text">violation ticket or other </span><span class="bold-text">charging document as appropriate </span>for the following SDP charges outside of APIS:
                                        <p class="mb-0 mt-0 pl-4" *ngIf="!availableTSA">•  TSA 94 (2) - Drive while unauthorized</p>
                                        <p class="mb-0 mt-0 pl-4" *ngIf="!availableTSA">•  TSA 94.1 (1) - Drive while suspended or disqualified under immediate roadside sanction</p>
                                        <p class="mb-0 mt-0 pl-4">•  Criminal Code (Canada) 320.18</p>
                                        <p class="mb-0 mt-0 pl-4">•  NDA 130</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="(stopInformation.isTSASelected || stopInformation.isTSASDPSelected) && availableTSA">
                    <div class="container d-flex justify-content-left">
                        <div class="row callout">
                            <div class="col-md-1 callout-logo">   
                                <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                </svg>
                            </div>
                            <div class="col-md-11 mt-4 mb-4">   
                                <h5>Reasonable Grounds to Believe <span class="label-hint">(Required)</span></h5>
                                <div class="form-check mt-2">
                                    <div class="custom-control custom-checkbox pl-1">
                                        <input required #chkTSAResonableGrounds="ngModel" type="checkbox" class="custom-control-input" id="chkTSAResonableGrounds" name="chkTSAResonableGrounds" [(ngModel)]="isTSAReasonableGroundsToBelieve" [ngClass]="{'is-invalid': (chkTSAResonableGrounds.touched || isSubmitClicked) && chkTSAResonableGrounds.invalid}">
                                        <label class="custom-control-label section-label" for="chkTSAResonableGrounds">
                                            I believe on reasonable and probable grounds and certify that the person named committed the offence as indicated and certify that I served an Offence Notice personally upon the person charged on the offence date. and/or I believe on reasonable and probable grounds and swear that the person named committed the offence as indicated and that I served a Summons personally upon the person charged on the offence date. I understand that this statement is deemed to have been made under oath.
                                        </label>
                                        <div class="invalid-feedback">
                                            <div>Reasonable Grounds to Believe must be checked</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
       
        <div class="card mt-4" *ngIf="(stopInformation.isTSASelected || stopInformation.isTSASDPSelected) && availableTSA">
            <div class="card-header">
                <div>Court Information</div>
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-12 col-sm-6">
                        <label for="courtLocation">Court Location <span class="label-hint">(Required)</span></label>
                        <select [appSelectValidator]="true" #courtLocationControl="ngModel" class="form-control" name="courtLocation" id="courtLocation" 
                        [(ngModel)]="stopInformation.courtCode" (ngModelChange)="onCourtTypeChange()"
                        [ngClass]="{'is-invalid':(courtLocationControl.touched || isSubmitClicked) && courtLocationControl.errors?.defaultSelected}">
                            <option value="">Select a Court Location</option>
                            <option *ngFor="let courtType of courtTypes" [value]="courtType.code">{{courtType.name}}</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Court Location is required</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label for="payCentreLocation">Pay Centre Code <span class="label-hint">(Required)</span></label>
                        <input required type="text" class="form-control" id="payCentreLocation" name="payCentreLocation" [formControl]="payCentreControl" [matAutocomplete]="autoPayCentre" placeholder="Enter and select a Pay Centre Code" [(ngModel)]="selectedPayCentreType"  [ngClass]="{'is-invalid': (payCentreControl.touched || isSubmitClicked) && payCentreControl.invalid}">
                        <mat-autocomplete #autoPayCentre="matAutocomplete" [displayWith]="displayPayCentre">
                            <mat-option *ngFor="let payCentreType of filteredPayCentres | async" [value]="payCentreType">
                                {{payCentreType.name}} ({{payCentreType.code}})
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error class="validation-message" *ngIf="(payCentreControl.touched || isSubmitClicked) && payCentreControl.hasError('required')">
                            Pay Centre Code is required
                        </mat-error>
                        <mat-error class="validation-message" *ngIf="(payCentreControl.touched || isSubmitClicked) && payCentreControl.hasError('forbiddenPayCentres') && !payCentreControl.hasError('required')">
                            Invalid Pay Centre Code option
                        </mat-error>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12 col-sm-6">
                        <label for="courtDate">Respond By/Appearance Date <span class="label-hint">(Required)</span></label>
                        <input required #courtDateControl="ngModel" type="text" bsDatepicker [bsConfig]="courtDatePickerConfig" isLessThanStartDate="{{courtStartDate | date:'yyyy-MM-dd'}}" isWorkingDay
                        maxlength="10" [(ngModel)]="stopInformation.courtDate" class="form-control" id="courtDate" name="courtDate" (ngModelChange)="onCourtDateChange()"
                        placeholder="yyyy/mm/dd" [ngClass]="{'is-invalid':(courtDateControl.touched || isSubmitClicked) && courtDateControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="courtDateControl.errors?.required">Respond By/Appearance Date is required</div>
                            <div *ngIf="!courtDateControl.hasError('required') && courtDateControl.hasError('EarlierDate')">Respond By/Appearance Date must be later than 21 days from the Occurrence Date {{occurrenceDate | date:'yyyy-MM-dd'}}</div>
                            <div *ngIf="!courtDateControl.hasError('required') && !courtDateControl.hasError('EarlierDate') && courtDateControl.hasError('Weekend')">Respond By/Appearance Date must be on a weekday</div>
                            <div *ngIf="!courtDateControl.hasError('required') && !courtDateControl.hasError('EarlierDate') && !courtDateControl.hasError('Weekend') && courtDateControl.hasError('Holiday')">Respond By/Appearance Date must be a working day</div>
                            <div *ngIf="!courtDateControl.errors?.required && !courtDateControl.hasError('EarlierDate') && !courtDateControl.hasError('Weekend') && !courtDateControl.hasError('Holiday')">Invalid Respond By/Appearance Date</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
						<label for="courtTime">Respond By/Appearance Time <span class="label-hint">(Required)</span></label>
						<input required #courtTimeControl="ngModel" type="text" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="courtTime" name="courtTime" placeholder="--:--" [(ngModel)]="stopInformation.courtTime" [ngClass]="{'is-invalid':(courtTimeControl.touched || isSubmitClicked) && courtTimeControl.invalid}">
						<div class="invalid-feedback">
                            <div *ngIf="courtTimeControl.errors?.required">Respond By/Appearance Time is required</div>
                            <div *ngIf="!courtTimeControl.errors?.required">Invalid Respond By/Appearance Time</div>
                        </div>
					</div>
                </div>
            </div>
        </div>
        <div class="card mt-4">
            <div class="card-header">
                <div>Issuance Information</div>
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-sm-6" *ngIf="(stopInformation.issueServiceTypeId != IssueServiceTypes.Mailed || stopInformation.isPaperSubmission) && (!stopInformation.isIRSSelected || stopInformation.isEmergencySubmission || stopInformation.isPaperSubmission )">
                        <label for="issuanceDate">Issuance Date <span class="label-hint">(Required)</span></label>
                        <input [readonly]="!stopInformation.isPaperSubmission" required [isFutureDate]="true" issuanceDateValidator="{{occurrenceDate | date:'yyyy-MM-dd'}}" isLessThanStartDate="{{occurrenceDate | date:'yyyy-MM-dd'}}" isGreaterThanEndDate="{{stopInformation.isPaperSubmission?Constants.Intake.PAPER_SUBMISSION_DEADLINE:''}}" #issuanceDateControl="ngModel" type="text" 
                        bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" class="form-control col-sm-8" id="issuanceDate" name="issuanceDate" placeholder="yyyy/mm/dd" [(ngModel)]="stopInformation.issueDate" [ngClass]="{'is-invalid':(issuanceDateControl.touched || isSubmitClicked) && issuanceDateControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="issuanceDateControl.hasError('required')">Issuance Date is required</div>
                            <div *ngIf="!issuanceDateControl.hasError('required') && issuanceDateControl.hasError('EarlierDate')">Issuance Date can't be earlier than the Occurrence Date {{occurrenceDate | date:'yyyy/MM/dd'}}</div>
                            <div *ngIf="!issuanceDateControl.hasError('required') && !issuanceDateControl.hasError('EarlierDate') && issuanceDateControl.hasError('LaterDate')">Issuance Date must be less than 2022/03/15</div>
                            <div *ngIf="!issuanceDateControl.hasError('required') && !issuanceDateControl.hasError('EarlierDate') && !issuanceDateControl.hasError('LaterDate') && issuanceDateControl.hasError('FutureDate')">Issuance Date can't be a future date</div>
                            <div *ngIf="!issuanceDateControl.hasError('required') && !issuanceDateControl.hasError('EarlierDate') && !issuanceDateControl.hasError('LaterDate') && !issuanceDateControl.hasError('FutureDate') && issuanceDateControl.hasError('MoreThan1Year')">
                                Issuance Date is more than a year from Occurrence Date, therefore this notice cannot be issued. <u><a class="font-weight-bold" [routerLink]="['/']">Cancel submission</a></u>.
                            </div>
                            <div *ngIf="!issuanceDateControl.hasError('required') && !issuanceDateControl.hasError('EarlierDate') && !issuanceDateControl.hasError('LaterDate') && !issuanceDateControl.hasError('FutureDate') && !issuanceDateControl.hasError('MoreThan1Year')">Invalid Issuance Date</div>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="stopInformation.issueServiceTypeId == IssueServiceTypes.Mailed || (stopInformation.isIRSSelected && !stopInformation.isPaperSubmission && !stopInformation.isEmergencySubmission)">
                        <label for="issuanceDate">Issuance Date <span class="label-hint">(Required)</span></label>
                        <input readonly required type="text" class="form-control col-sm-8" issuanceDateValidator="{{occurrenceDate | date:'yyyy-MM-dd'}}" id="issuanceDate" name="issuanceDate" #issuanceDate="ngModel" [ngModel]="stopInformation.issueDate | date:'yyyy/MM/dd'"
                        [ngClass]="{'is-invalid': issuanceDate.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="issuanceDate.hasError('MoreThan1Year')" class="error">
                                Issuance Date is more than a year from Occurrence Date, therefore this notice cannot be issued. <u><a class="font-weight-bold" [routerLink]="['/']">Cancel submission</a></u>.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="policeFileNumber">Police File # <span class="label-hint">(Max 10 characters)</span></label>
                        <input type="text" maxlength="10" class="form-control" id="policeFileNumber" name="policeFileNumber" [(ngModel)]="stopInformation.policeFileNumber" placeholder="Enter Police File Number">
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="agencyModal">Agency/Service <span class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="true" #agencyControl="ngModel" class="form-control" name="agencyModal" id="agencyModal" [(ngModel)]="selectedAgencyId" [ngClass]="{'is-invalid':(agencyControl.touched || isSubmitClicked) && agencyControl.errors?.defaultSelected}" (change)="onAgencyChange()">
                                <option [ngValue]="null">Select an Agency/Service</option>
                                <option *ngFor="let agency of agencies" [value]="agency.agencyId">{{agency.agencyName}}</option>            
                            </select>
                            <div class="invalid-feedback">
                                <div>Agency/Service is required</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <label for="detachmentModal">Detachment/Employer <span class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="true" #detachmentControl="ngModel" class="form-control" name="detachmentModal" id="detachmentModal" [(ngModel)]="selectedDetachmentId" [ngClass]="{'is-invalid':(detachmentControl.touched || isSubmitClicked) && detachmentControl.errors?.defaultSelected}" (change)="onDetachmentChange(selectedDetachmentId)">
                                <option [ngValue]="null">Select a Detachment/Employer</option>
                                <option *ngFor="let detachment of detachments | detachmentsFilter:selectedAgencyId" [value]="detachment.detachmentId">{{detachment.detachmentName}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Detachment/Employer is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="enablePickupAddressSelection">
                        <div class="col-12">
                            <label for="pickupAddressModal">Licence Pick-Up Address <span class="label-hint">(Required)</span></label>
                            <select [appSelectValidator]="true" #pickupAddressControl="ngModel" class="form-control" name="pickupAddressModal" id="pickupAddressModal" [(ngModel)]="selectedPickupAddressId" (ngModelChange)="onPickupAddressChange($event)"  [title]="selectedPickupAddressTitle" [ngClass]="{'is-invalid':(pickupAddressControl.touched || isSubmitClicked) && pickupAddressControl.errors?.defaultSelected}">
                                <option [ngValue]="null">Select a Licence Pick-Up Address</option>
                                <option *ngFor="let pickupAddress of pickupAddresses" [value]="pickupAddress.id">{{pickupAddress.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                <div>Licence Pick-Up Address is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                        
                <div class="form-group" *ngIf="!stopInformation.isEmergencySubmission && !stopInformation.isPaperSubmission">
                    <div class="row mt-3">
                        <div class="col-12">
                            <label class="w-100">Printer Paper Size <span class="label-hint">(Required)</span></label>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="standardSize" name="paperSize" #standardSizeControl="ngModel" [value]="false" [(ngModel)]="stopInformation.isMobilePrint" [ngClass]="{'is-invalid':(standardSizeControl.touched || mobileSizeControl.touched || isSubmitClicked) && standardSizeControl.invalid}">
                                <label class="custom-control-label" for="standardSize">8.5 x 11"</label>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input required type="radio" class="custom-control-input" id="mobileSize" name="paperSize" #mobileSizeControl="ngModel" [value]="true" [(ngModel)]="stopInformation.isMobilePrint" [ngClass]="{'is-invalid':(standardSizeControl.touched || mobileSizeControl.touched || isSubmitClicked) && mobileSizeControl.invalid}">
                                <label class="custom-control-label" for="mobileSize">4" wide</label>
                            </div>
                            <div *ngIf="(standardSizeControl.touched || mobileSizeControl.touched || isSubmitClicked) && mobileSizeControl.invalid">
                                <div class="error">Please select one</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group card mt-4 mb-4" *ngIf="stopInformation.isIRSSelected || stopInformation.isSDPSelected">
            <div class="container d-flex justify-content-left">
                <div class="row callout">
                    <div class="col-md-1 callout-logo">
                        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle mt-2 mb-2" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                        </svg>
                    </div>
                    <div class="col-md-11 mt-4 mb-4">
                        <h5>Declaration <span class="label-hint">(Required)</span></h5>
                        <div class="form-check mt-2">
                            <div class="custom-control custom-checkbox pl-1">
                                <input required #chkConsentControl="ngModel" type="checkbox" class="custom-control-input" id="chkConsent" name="chkConsent" [(ngModel)]="isTrueInformation" [ngClass]="{'is-invalid': (chkConsentControl.touched || isSubmitClicked) && chkConsentControl.invalid}">
                                <label class="custom-control-label section-label" for="chkConsent">
                                    {{officerSubmissionDeclaration.description}}
                                </label>
                                <div class="invalid-feedback">
                                    <div>Declaration must be checked</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="row pt-3">
                <div class="col-12 col-sm-6">                
                    <a *ngIf="(stopInformation.isIRSSelected && !isCommercialContravention()) || stopInformation.isSDPSelected" class="btn button-secondary btn-block" [routerLink]="['/contravention/submission/vehicle-seizure']">Back to Vehicle Seizure</a>
                    <a *ngIf="!((stopInformation.isIRSSelected && !isCommercialContravention()) || stopInformation.isSDPSelected) && stopInformation.recipient.recipientTypeId != RecipientTypes.NotInVehicle" class="btn button-secondary btn-block" [routerLink]="['/contravention/submission/vehicle-information']">Back to Vehicle Information</a>
                    <a *ngIf="stopInformation.recipient.recipientTypeId == RecipientTypes.NotInVehicle" class="btn button-secondary btn-block" [routerLink]="['/contravention/submission/contravention-selection']">Back to Contravention Selection</a>
                </div>
                <div class="col-12 col-sm-6 text-right">                
                    <button class="btn button-primary btn-block" [disabled]="isSubmitted" (click)="onSubmitClick(PrintAndIssueForm.valid)">Submit</button>   
                </div>
            </div>
            <div class="card error-card row mt-2">
                <div class="col-12">
                    <div class="error">
                        <p *ngFor="let error of errors">• {{error}}</p>
                    </div>          
                </div>
            </div>
        </div>        
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white">Processing. Please Wait...</p></ngx-spinner>      
</form>  

